import { PromptTemplateEditor } from '../components/PromptTemplate/PromptTemplateEditor';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';

export function Component() {
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <PromptTemplateEditor template={null} initialEditing />;
    </AdminView>
  );
}
